import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap is imported

import Panel from "@/shared/components/Panel";
import { Ring } from "@uiball/loaders";
import { getProject } from "../../../utils/services/user.service";

const MyProject = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [myProjectdata, setMyProjectData] = useState([])
    const fetchMyProject = async () => {

        setIsLoading(true)
        try {
            const res = await getProject()
            setMyProjectData(res?.data?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log("🚀 ~ fetchMyProject ~ error:", error)

        }
    }

    useEffect(() => {
        fetchMyProject()
    }, [])

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Panel lg={12} title="My Project" closeBtn minusBtn refreshLoad>

                {
                    myProjectdata.length ==0 && <h5>No Project Found!</h5>
                }


            </Panel>
        </>
    );
};

export default MyProject;
