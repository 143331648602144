import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap is imported

import Panel from "@/shared/components/Panel";
import { Ring } from "@uiball/loaders";
import { getAccessRequestProject, updateAccessRequestProject } from "../../../utils/services/user.service";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import { getStatusBadge } from "../../../utils/constants/contants";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const UserRequests = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [accessRequestData, setAccessRequestData] = useState([])
    const { projectId } = useParams();

    const fetchAccessRequest = async () => {
        setIsLoading(true)
        try {
            const payload = { limit: 100000, page_number: 1, email: "", project_id: projectId }

            const res = await getAccessRequestProject(payload)
            setAccessRequestData(res?.data?.data)
            setIsLoading(false)

        } catch (error) {
            console.log("🚀 ~ getgetAccessRequest ~ error:", error)
            setIsLoading(false)

        }
    }
    useEffect(() => {
        fetchAccessRequest()
    }, [])


    const handleStatusChange = async (event, item) => {
        setIsLoading(true);
        const newStatus = event.target.value;
        const payload = {
            project_id: item?.project_id,
            user_id: item?.user_id,
            update_status: newStatus
        }
        try {
            const res = await updateAccessRequestProject(payload);
            setIsLoading(false);
            await fetchAccessRequest()
            toast.success(res?.data?.Message);
        } catch (error) {

            setIsLoading(false);
            toast.error(error?.response?.data.Message || 'An error occurred while updating status:');
        }
    };
    const Ava = `/img/avat.png`;

    const tableConfig = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        isPagination: true,
    };

    const header = [
        { accessor: "user_id", Header: "User Id" },
        { accessor: "name", Header: "Name" },
        { accessor: "email", Header: "Email" },
        { accessor: "status", Header: "Status" },
        {
            accessor: "action",
            Header: "Action",
            disableGlobalFilter: true,
        },
    ];
    const data = [];
    accessRequestData.map((item) =>
        data.push({
            user_id: item.user_id,
            name: (<div className="d-flex flex-row align-items-center gap-2 ">
                <img className="rounded-5" style={{
                    width: '44px',
                    height: "44px"
                }} src={item?.user_detail[0]?.image || Ava} />
                <span className="d-flex text-nowrap">
                    {item?.user_detail[0]?.name}
                </span>
            </div>),
            email: (

                <span className="d-flex text-nowrap">
                    {item?.user_detail[0]?.email}
                </span>
            ),
            status: (

                <span className="d-flex flex-row align-items-center w-100 text-center ">
                    {getStatusBadge(item?.status)}
                </span>
            ),
            action: (

                <select
                    id={`statusDropdown-${item._id}`}
                    onChange={(e) => handleStatusChange(e, item)}
                    value={item.status}
                    className={`appearance-none w-full ${item?.status =="pending" ? "bg-warning" : item?.status == 'approved' ? "bg-success" :"bg-danger"} text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500`}
                >
                    {item.status === "pending" && (
                        <option>Pending</option>
                    )}
                    <option
                        value="approved"
                        className={"approved"}
                    >
                        Approved
                    </option>
                    <option
                        value="rejected"
                        className={"rejected"}
                    >
                        Rejected
                    </option>
                </select>
            ),


        })
    );

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Panel lg={12} title="User Requests" closeBtn minusBtn refreshLoad>



                <ReactTableBase
                    columns={header}
                    data={data}
                    tableConfig={tableConfig}
                />
                {accessRequestData.length == 0 && <h5>No Request Found!</h5>}


            </Panel>
        </>
    );
};

export default UserRequests;
