import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
    editProjectOtcPackage,
    getAllTokens,
    getApprovers,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Button } from "@/shared/components/Button";
import { useChainContext } from "../../../containers/context/context";

const UpdateProjectOTCPackage = ({
    show,
    hide,
    reFetchPackages,
    data,
    marketplace_id,
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [chain_id, setChain_id] = useState(null);
    const [packageChoice, setPackageChoice] = useState("active");
    const [pool1, setPool1] = useState("");
    const [pool2, setPool2] = useState("");
    const [allTokens, setAllTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState("");
    const [allTokensPool2, setAllTokensPool2] = useState([]);
    const [searchLoad, setsearchLoad] = useState(false);
    const [searchUserId, setSearchUserId] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const { reFethData, setReFetchData } = useChainContext()
    const [packageStatus, setpackageStatus] = useState(data?.package_status);


    useEffect(() => {
        // Filter and map the allTokens array
        const options = allTokens.map(token => ({
            value: token.symbol,
            label: token.symbol
        }));

        // Update the state with the new dropdown options
        setDropdownOptions(options);

        const Pool2Coins = allTokens
            // .filter(token => token.symbol !== 'BTC')  // Filter out the BTC token
            .map(token => ({
                value: token.symbol,
                label: token.symbol
            }));

        setAllTokensPool2(Pool2Coins);




    }, [allTokens]);
    const packageStatusOptions = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
    ];
    const [packageInfo, setpackageInfo] = useState({
        packageName: data.package_name || "",
        packagePrice: data?.price || "",
        buyAmount: null,
        token: data?.token || "",
        transfer_fee: null,
        description: data?.package_description || "",
        contract_address: "",
        unlockDay: data?.unlock_days || "",


    });
    const { packageName, packagePrice, buyAmount, token, description, unlockDay } =
        packageInfo;
    const initialTokenData = dropdownOptions.map((item) => ({
        symbol: null,
        price: null,
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setpackageInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };
    const CreateNewOtcMarketplacePackage = async () => {

        try {
            if (
                !packageName ||
                !description ||
                !unlockDay ||
                !packagePrice
            ) {

                toast.error("Please fill in all required fields");
                return;
            }

            setIsLoading(true);
            const poolVal = [pool1, pool2].join("-");
            const payload = {
                "package_id": data._id,
                market_place_id: marketplace_id,
                "price": packagePrice,
                "token": token,
                "package_name": packageName,
                "package_description": description,
                "unlock_days": unlockDay,
                "package_choice": packageChoice,
                "package_status":packageStatus
            }
            const res = await editProjectOtcPackage(payload);
            toast.success(res.data.Message);
            setIsLoading(false);
            setReFetchData(!reFethData);
            hide();
        } catch (error) {
            // Handle error
            toast.error(error?.response?.data.Message);
            setIsLoading(false);
        }
    };


    const fetchAllTokens = async () => {
        const payload = {
            chain_id: "",
            limit: 100,
            page_number: 1
        };
        const res = await getAllTokens(payload);
        setAllTokens(res.data.user);
    };
    useEffect(() => {
        fetchAllTokens();
    }, []);
 
    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b> Create Project OTC Package</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div
                        className="d-flex flex-column"
                        style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                    >
                        <div className="d-flex flex-row flex-wrap gap-4 ">
                            <div className="col-12 col-md-4">
                                <label>Package Name</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="packageName"
                                        value={packageName}
                                        className="form-control mt-2"
                                        placeholder="Package Name"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>
                            <div className="col-12 col-md-4">
                                <label>Package Price</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="packagePrice"
                                        value={packagePrice}
                                        className="form-control mt-2"
                                        placeholder="Package Price"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>
                            <div className="col-12 col-md-3">
                                <label>Unlock Days</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="number"
                                        name="unlockDay"
                                        value={unlockDay}
                                        className="form-control mt-2"
                                        placeholder="Enter Unlock Days"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>
                            <div className="col-12 col-md-3">
                                <label>Description</label>

                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        name="description"
                                        value={description}
                                        className="form-control mt-2"
                                        placeholder="Enter Description"
                                        onChange={handleChange}
                                    />
                                </InputDiv>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3">
                                <label>Package Status</label>

                                <InputDiv>
                                    <DropdownDiv className="mb-0">
                                        <CustomSelect
                                            isLoading={searchLoad}
                                            name="receiver"
                                            placeholder={`Package Status`}
                                            onChange={(value) => setpackageStatus(value.value)}
                                            value={packageStatus}
                                            options={packageStatusOptions
                                            }
                                            isSearchable
                                        />
                                    </DropdownDiv>
                                </InputDiv>
                            </div>

                        </div>
                       
                        <div className="d-flex justify-content-center">
                            <FormButton
                                type="submit"
                                variant="contain-success"
                                className="py-2 mt-2 col-12 col-md-4"
                                onClick={CreateNewOtcMarketplacePackage}
                            >
                                Update Package
                            </FormButton>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
export default UpdateProjectOTCPackage;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
