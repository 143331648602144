/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";
import ProjectOtc from "./ProjectOtc";
import ProjectVoting from "./ProjectVoting";
import UserRequests from "./UserRequests";
import { checkMyAccess, getMyOtcBuyHistory } from "../../../utils/services/user.service";
import ProjectAccess from "./ProjectAccess";
import { useChainContext } from "../../../containers/context/context";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import moment from "moment";
import OrderTrackingTable from "./OrderTrackingTable";
import InfoItem from "../../../shared/components/OtcMarketplace/InfoItem";
import StatusComponent from "../../../shared/components/StatusCompoent/StatusComponent";
import { Link } from "react-router-dom";
import SocialMediaLink from "../../../shared/components/OtcMarketplace/SocialMediaLink";
import ProjectDetailPage from "./ProjectDetailPage";

const MyPtojectDetails = () => {
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { globalProjectOtcId,
        setGlobalProjectOwnerId, } = useChainContext()
    const [projectDetaiData, setProjectDetailData] = useState(null)
    console.log("🚀 ~ MyPtojectDetails ~ projectDetaiData:", projectDetaiData)
    const [buyHistoryData, setBuyHistoryData] = useState([]);
    const user = JSON.parse(localStorage?.getItem('user'))
    const ProjectDetail = async () => {
        setIsLoading(true)
        try {
            const payload = {
                project_id: projectId
            }
            const res = await checkMyAccess(payload)
            setProjectDetailData(res?.data)
            setGlobalProjectOwnerId(res?.data?.data[0]?.user_id)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)

        }
    }
    useEffect(() => {
        ProjectDetail()
        getBuyHistory()

    }, [projectId])

    const getBuyHistory = async () => {
        const payload = {
            project_otc_id: projectDetaiData.otc_id,
        };
        const res = await getMyOtcBuyHistory(payload);
        setBuyHistoryData(res?.data?.data);
    };

    const header = [
        { accessor: "date", Header: "Date" },
        { accessor: "buy_amount", Header: "Buy Amount" },
        { accessor: "receive_amount", Header: "Receive Ammount" },
        { accessor: "buy_fee_type", Header: "Buy Fee Type" },
        { accessor: "buy_tax", Header: "Buy Tax" },
        // { accessor: "status", Header: "Status" },
    ];
    const tableConfig = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        isPagination: true,
    };

    let data1 = [];
    buyHistoryData?.map((item) =>
        data1.push({
            date: moment(item.updatedAt).format('ll'),
            buy_amount: item?.buy_amount,
            receive_amount: item?.receive_amount,
            buy_fee_type: item?.buy_fee_type,
            buy_tax: item?.buy_tax,

        })
    );
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 99999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div className="container py-3">


                <div
                    className="position-fixed bottom-0 end-0 p-3"
                    style={{ zIndex: 9999 }}
                >
                    {user?.data?.user?.user_id !== projectDetaiData?.data[0]?.user_id && (
                        <div className="row w-25">
                            <Link
                                to={`/livechat?project=${projectDetaiData?.data[0]?.user_id}&name=${encodeURIComponent(
                                    projectDetaiData?.data[0]?.owner_details[0]?.name || ""
                                )}`}
                            >
                                <i className="ml-1 fa-2xl fa fa-comments" />
                            </Link>
                        </div>
                    )}

                </div>
                <h2 hidden className="text-center mb-4">
                    OTC Marketplace Details
                </h2>
                <div className="row px-md-4 mt-md-2 position-relative ">
                    <div className="col-lg-12 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-3">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12">

                            </div>
                        </div>

                        {/* <div>
                            <img

                                src={projectDetaiData?.data[0]?.image}
                                alt={`Banner `}
                                style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    minHeight: "150px",
                                    maxHeight: "150px",
                                    objectFit: "cover",
                                    opacity: "0.5",
                                    filter: "blur(1px)", // Apply blur effect
                                }}
                            />
                        </div> */}

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <Tabs
                                    defaultActiveKey="projectDetail"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3 mt-4"
                                >
                                    <Tab
                                        eventKey="projectDetail"
                                        ClassName="text-white"
                                        title="Project Details"
                                    >


                                        {/* <div className="row text-center">
                                            <InfoItem
                                                label="Project ID"
                                                value={projectDetaiData?.data[0]?._id}
                                            />
                                            <InfoItem
                                                label="Owner Name"
                                                value={projectDetaiData?.data[0]?.owner_details[0]?.name}
                                            />
                                            <InfoItem
                                                label="Project Name"
                                                value={projectDetaiData?.data[0]?.name}
                                            />
                                            <InfoItem
                                                label="Email"
                                                value={projectDetaiData?.data[0]?.email}
                                            />
                                            <InfoItem
                                                label="Phone Number"
                                                value={projectDetaiData?.data[0]?.phone_number}
                                            />

                                            <SocialMediaLink platform="Explorer Link" url={projectDetaiData?.data[0]?.explorer_link} />

                                            <InfoItem
                                                label="Description"
                                                value={projectDetaiData?.data[0]?.description}
                                            />
                                            <StatusComponent
                                                title=" Status"
                                                status={projectDetaiData?.data[0]?.status}
                                            />
                                            {projectDetaiData?.data[0]?.insta_link &&
                                                <SocialMediaLink platform="Instagram" url={projectDetaiData?.data[0]?.insta_link} />
                                            }
                                            {projectDetaiData?.data[0]?.telegram_link &&
                                                <SocialMediaLink platform="Telegram" url={projectDetaiData?.data[0]?.telegram_link} />
                                            }
                                            {projectDetaiData?.data[0]?.x_link &&
                                                <SocialMediaLink platform="X" url={projectDetaiData?.data[0]?.x_link} />
                                            }
                                            {projectDetaiData?.data[0]?.document?.length > 0 &&
                                                <SocialMediaLink platform="Document" url={projectDetaiData?.data[0]?.document[0]} />
                                            }




                                        </div> */}

                                        <ProjectDetailPage projectId={projectId} data={projectDetaiData?.data[0]}  />


                                    </Tab>
                                    <Tab
                                        eventKey="home"
                                        ClassName="text-white"
                                        title=" OTC"
                                    >
                                        {projectDetaiData?.status == 'approved' || user?.data?.user?.user_id == projectDetaiData?.data[0]?.user_id ?
                                            <ProjectOtc projectDetaiData={projectDetaiData?.status}
                                                userId={user?.data?.user?.user_id}
                                                project_Owner_id={projectDetaiData?.data[0]?.user_id} />
                                            : <h5>You don't have project access please get accesss from Get Access Tab</h5>}
                                    </Tab>
                                    <Tab
                                        eventKey="buy_history"
                                        ClassName="text-white"
                                        title="OTC Activity"
                                    >

                                        {projectDetaiData?.status == 'approved' || user?.data?.user?.user_id == projectDetaiData?.data[0]?.user_id ?
                                            <ReactTableBase
                                                columns={header}
                                                data={data1}
                                                tableConfig={tableConfig}
                                            /> : <h5>You don't have project access please get accesss from Get Access Tab</h5>}

                                    </Tab>

                                    {user?.data?.user?.user_id !== projectDetaiData?.data[0]?.user_id && projectDetaiData?.status != 'approved' && <Tab
                                        eventKey="get access"
                                        ClassName="text-white"
                                        title="Get Access"
                                    >
                                        <ProjectAccess />

                                    </Tab>}
                                    {user?.data?.user?.user_id == projectDetaiData?.data[0]?.user_id && <Tab
                                        eventKey="transections"
                                        ClassName="text-white"
                                        title="User Requests"
                                    >
                                        <UserRequests />

                                    </Tab>}
                                    {<Tab
                                        eventKey="holderGraph"
                                        ClassName="text-white"
                                        title="Tracking"
                                    >
                                        <div className="">

                                            {projectDetaiData?.status == 'approved' || user?.data?.user?.user_id == projectDetaiData?.data[0]?.user_id ? <OrderTrackingTable />
                                                : <h5>You don't have project access please get accesss from Get Access Tab</h5>}
                                        </div>
                                    </Tab>}
                                    {<Tab
                                        eventKey="voting"
                                        ClassName="text-white"
                                        title="Voting System"
                                    >
                                        {projectDetaiData?.status == 'approved' || user?.data?.user?.user_id == projectDetaiData?.data[0]?.user_id ? <ProjectVoting />
                                            : <h5>You don't have project access please get accesss from Get Access Tab</h5>}
                                    </Tab>}

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyPtojectDetails;