import React from "react";
import { Button } from "../Button";
import { FaInstagram, FaTelegramPlane, FaTwitter, FaFileAlt } from "react-icons/fa";
import { AiOutlineFile } from "react-icons/ai"; // Example for X (Twitter)
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

// Mapping icons to platforms
const platformIcons = {
    Instagram: <FaInstagram />,
    Telegram: <FaTelegramPlane />,
    X: <FaTwitter />, // Replace with the appropriate icon for X
    Document: <FaFileAlt />
};

const SocialMediaLink = ({ platform, url, index }) => {
    console.log("🚀 ~ SocialMediaLink ~ url:", url);
    if (!url) return null; // Hide if no URL is provided

    return (
        <div className="col-lg-3 py-2 col-md-12 col-sm-4">
            <p className="py-1">
                <b style={{ fontWeight: "700" }}>{platform}</b>
            </p>
            <Button
                size="sm"
                className="btn btn-sm mb-2 px-2"
                variant="outline-success"
            >
                <a
                    href={`${url.startsWith("http") ? url : `www.${url}`}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                    style={{ textDecoration: "none", display: "flex", alignItems: "center", gap: "5px" }}
                >
                    {platformIcons[platform] || <FontAwesomeIcon icon={faExternalLinkAlt} />
                    } {/* Show the icon */}
                    {index !== undefined ? ` ${index + 1}` : ""}
                </a>
            </Button>
        </div>
    );
};

export default SocialMediaLink;
