import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button';
import { addTelegramAccount, sendOtpDynamic } from '../../../../utils/services/user.service';
import { useState, useEffect } from 'react';
import { Ring } from '@uiball/loaders';
import { toast } from 'react-toastify';

const AddTelegramModal = ({ show, hide, reFetch }) => {
    const [username, setUsername] = useState('');
    const [chanelId, setChanelId] = useState('');
    const [otp, setOtp] = useState('');
    const [telegramAccountName, setTelegramAccountName] = useState(''); // New State
    const [timer, setTimer] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
        } else {
            clearInterval(countdown);
            setIsResendDisabled(false);
        }
        return () => clearInterval(countdown);
    }, [timer]);

    const getOtpCode = async () => {
        try {
            setIsLoading(true);
            setIsResendDisabled(true);
            setTimer(120); // Start 2-minute timer

            const payload = {
                type: 'telegram_account_otp',
            };
            const res = await sendOtpDynamic(payload);
            toast.success(res?.data?.message);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setIsResendDisabled(false);
            toast.error(error?.response?.data?.message || 'Something went wrong');
        }
    };

    const linkTelegram = async () => {
        try {
            if (!username || !otp || !telegramAccountName) {
                alert('Please fill out all required fields');
                return;
            }

            setIsLoading(true);
            const payload = {
                telegram_bot_token: username,
                channel_id: chanelId,
                otp,
                telegram_account_name: telegramAccountName, // Include in payload
            };
            const res = await addTelegramAccount(payload);
            toast.success(res?.data?.message);
            setIsLoading(false);
            reFetch();
            hide();
        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response?.data?.Message || 'Something went wrong');
        }
    };

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 999999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>

            <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: 'large' }}>
                        <b>Link Telegram Account</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x p-0">
                        <div className="card gap-2 mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                            <div className="flex flex-col col-12">
                                <label>Telegram Account Name</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        value={telegramAccountName}
                                        onChange={(e) => setTelegramAccountName(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter Telegram Account Name"
                                    />
                                </InputDiv>
                            </div>
                            <div className="flex flex-col col-12">
                                <label>Telegram Bot Token</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter Bot Token"
                                    />
                                </InputDiv>
                            </div>
                            <div className="flex flex-col col-12">
                                <label>Channel ID</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        value={chanelId}
                                        onChange={(e) => setChanelId(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter Channel ID"
                                    />
                                </InputDiv>
                            </div>
                            <div className="flex flex-col col-12">
                                <label>OTP *</label>
                                <InputDiv>
                                    <ChatSearchInput
                                        type="text"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter OTP"
                                    />
                                </InputDiv>
                            </div>

                            <div className="d-flex flex-column align-items-center flex-md-row w-100">
                                <FormButton
                                    disabled={!isResendDisabled}
                                    variant={'outline-primary'}
                                    onClick={linkTelegram}
                                >
                                    Link Account
                                </FormButton>
                                <FormButton
                                    variant={'outline-primary'}
                                    onClick={getOtpCode}
                                    disabled={isResendDisabled}
                                >
                                    {isResendDisabled ? `Resend in ${timer}s` : 'GET OTP'}
                                </FormButton>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddTelegramModal;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 14px;
`;

const FormButton = styled(Button)`
  margin-top: 1rem;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::placeholder {
    color: #b1b1b1;
  }
`;
