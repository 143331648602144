import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "@/shared/components/Button";


const ConfirmChangeStatusModal = ({ show, hide, onClick }) => {
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >

                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b>Warning! </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x p-0">
                        <div
                            className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
                            style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                        >
                            <div>
                                <p className="text-black">
                                    This action will stop the voting permanently and cannot be made active again.                </p>
                            </div>
                            <div className="d-flex flex-column flex-md-row w-full gap-2 gap-md-4 justify-center items-center">

                                <FormButton
                                    type="submit"
                                    variant="outline-success"
                                    className="py-2 mt-2 w-100"
                                    onClick={onClick}

                                >
                                    Yes
                                </FormButton>

                                <FormButton
                                    type="submit"
                                    variant="contain-success"
                                    className="py-2 mt-2 w-100"
                                    onClick={hide}

                                >
                                    No
                                </FormButton>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ConfirmChangeStatusModal;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;