/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';
import { Ring } from '@uiball/loaders';
import Slider from 'react-slick';
// import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-toastify/dist/ReactToastify.css';
import '../style/product.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TopbarNew from '../../../containers/Layout/topbar/TopbarNew';
import { Button } from '../../../shared/components/Button';
import CustomOfferModal from '../../../shared/components/customOfferModal/CustomOfferModal';
import { useLocation, useParams } from 'react-router-dom';
import { getProjectImagesDetailById } from '../../../utils/services/user.service';

const MediaDetailPage = () => {
    // console.log('MediaDetailPage Rendor');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [mediaData, setMediaData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

    };

    const NoImg = `${process.env.PUBLIC_URL}/img/no-img.png`;




    const getProjectImagesDetailByIdFn = async () => {
        setIsLoading(true)

        try {
            const payload = {
                record_id: id
            }
            const res = await getProjectImagesDetailById(payload)
            setMediaData(res?.data?.data)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getProjectImagesDetailByIdFn()
    }, [id])

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? 'flex' : 'none', // Use flex display when loading
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Container className="mt-4 containerDiv">
                <div className="row">
                    <div className="col-lg-4 hero-img py-1 justify-content-center">
                        {mediaData?.image?.length !== 0 ? (
                            <div className="carousel-container">
                                <Slider {...settings}>
                                    {mediaData?.image?.map((image, index) => (
                                        <div className="square-img-wrapper">
                                            <img src={image} className="card-img-top square-img" alt={`Imagee ${index + 1}`} />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <div className="square-img-wrapper mt-4">
                                <img
                                    src={NoImg}
                                    className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                                    alt={product.storeName}
                                />
                            </div>
                        )}
                    </div>
                    <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-5">
                        <h2 id="h2" style={{ textTransform: 'Capitalize' }}>{mediaData?.title}</h2>

                        <div className="row px-2 py-4">

                        </div>
                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="profile" title="Description">
                                <p style={{ textTransform: 'capitalize' }} className="mb-2">{mediaData?.description}</p>
                            </Tab>
                            <Tab eventKey="detail" title="Details">
                                <Row xs={2} md={6} lg={4} className="text-center">

                                    {mediaData?.document?.length > 0 ? (
                                        <Col xs={12} md={6} lg={6}>
                                            <p className="py-1 mb-1"> <b style={{ fontWeight: '600' }}> Documents </b></p>
                                            {mediaData?.document?.map((doc, index) => (
                                                <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-success">
                                                    <a
                                                        href={doc}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-white"
                                                    >
                                                        Doc {index + 1}
                                                    </a>
                                                </Button>
                                            ))}
                                        </Col>
                                    ) : (
                                        <Col>
                                            <p className="py-1"> <b style={{ fontWeight: '600' }}> Document </b></p>
                                            <h6 style={{ textTransform: 'capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">No Document Found</h6>
                                        </Col>
                                    )}
                                </Row>
                            </Tab>
                        </Tabs>

                    </div>
                </div>



            </Container>
        </>
    );
};

export default MediaDetailPage;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;