/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Ring } from '@uiball/loaders';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-toastify/dist/ReactToastify.css';
import '../style/product.css';
import {
  addCertificate,
  getApprover,
  getCurrentOwner,
  getProductInformation,
  getTrackingInnerInfo,
  transferOwnership,
} from '../../../utils/services/user.service';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../shared/components/Button';
import OwnerStepper from './OwnerStepper';
import { CustomSelect } from '../../../shared/components/CustomSelect';
import { toast } from 'react-toastify';
import { Formik } from "formik";
import LocationSearch from './LocationSearch';


const ProductTrackingDetails = () => {

  const { productId } = useParams(); // Extracts the product ID from the URL
  const [product, setProduct] = useState([]);
  const [currentOwners, setCurrentOwners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOwnershipButton, setShowOwnershipButton] = useState(true)
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [currentOwnerId, setCurrenOwnerId] = useState("");
  const [activeTab, setActiveTab] = useState('profile'); // State for active tab
  const [coordinates, setCoordinates] = useState(null);

  const myUserId = JSON.parse(localStorage.getItem("user")).data.user.user_id

  const fetchProductInformation = async () => {
    try {
      setIsLoading(true)
      const payload = {
        record_id: productId

    }
      const res = await getTrackingInnerInfo(payload)
      setProduct(res?.data?.data)
      setCurrentOwners(res?.data?.data[0]?.current_owner)
      setCurrenOwnerId(res?.data?.data[0]?.current_owner[0]?.current_owner_id)

      setIsLoading(false)

    } catch (error) {
      console.log("🚀 ~ fetchProductInformation ~ error:", error)
      setIsLoading(false)

    }
  }

  useEffect(() => {
    fetchProductInformation()
  }, [productId])


  // if (!product) {
  //   return <div>Loading...</div>;
  // }
  const NoImg = `${process.env.PUBLIC_URL}/img/no-img.png`;

  const handleInputChange = (name) => {

    // setsearchLoad(true);

    if (name.length >= 2) {
      getApprover({ name })
        .then((res) => {
          // setsearchLoad(false);

          const arr = res.data.user.map((i) => ({
            id: i._id,
            address: i.address,
            value: i.name,
            label: i.name,
            // sol_address: i?.sol_address,
            // onChainAddress: i?.secondary_wallet_address,
            // onChainSolAddress: i?.secondary_wallet_sol_address,
            // trcWalletAddress: i?.trc_address,
            // striga_user_id: i?.striga_user_id
          }));

          // setsearchLoad(false);
          // Case-insensitive comparison
          const matchingOptions = arr.filter(
            (option) => option.value.toLowerCase() === name.toLowerCase()
          );
          setUsersList(matchingOptions)
          console.log("🚀 ~ .then ~ matchingOptions:", matchingOptions)

        })
        .catch((error) => {

        });
    } else {
      // setapproverData([]); // Clear options when input length is less than 2
    }
  };

  const transferOwnerFnc = async () => {
    setIsLoading(true)
    try {
      const payload = {
        tracking_info_id: product[0]?.tracking_info[0]?._id,
        new_owner_id: selectedUser?.id,
      };

      const res = await transferOwnership(payload)
      console.log("🚀 ~ transferOwnerFnc ~ res:", res)
      setIsLoading(false)
      fetchProductInformation()
      setActiveTab('profile');


    } catch (error) {
      setIsLoading(false)
      toast.error(error?.response?.data?.Message)

    }
  }
  const AddCertificationsData = async (values) => {
    setIsLoading(true)
    const formData = new FormData();

    // Add the product ID to FormData
    formData.append("product_id", product[0]?._id);

    // Append files to FormData
    values.files.forEach((file, index) => {
      formData.append(`File`, file); // Add files with unique keys
    });

    try {
      const res = await addCertificate(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        setIsLoading(false)
        toast.success("Certification data added successfully!");
        fetchProductInformation()
      } else {
        toast.error("Failed to add certification data.");
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error uploading data:", error);
      toast.error("An error occurred while uploading data.");
    }
  };
  const handleCoordinatesChange = (newCoordinates) => {
    setCoordinates(newCoordinates);
};
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container className="mt-4 containerDiv">
        {product.length > 0 ? <div className="row">
          <div className="col-lg-4 hero-img py-1 justify-content-center">
            <div className="square-img-wrapper">
              <img src={product[0]?.images[0] || NoImg} className="card-img-top square-img" alt={`Imagee`} />
            </div>
          </div>



          <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-5">
            <h2 id="h2" style={{ textTransform: 'Capitalize' }}>{product[0]?.title}</h2>




            <div className="row px-2 py-4">
              <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                <Row xs={2} md={6} lg={4} style={{ textTransform: 'Capitalize' }}>
               
                  <Col>
                    <p className="py-1 mb-0"> <b> Batch Number</b></p>
                    <p className="py-0 mb-2">{product[0]?.batch_number || "--"}</p>
                  </Col>
                  <Col>
                    <p className="py-1 mb-0"> <b> Origin</b></p>
                    <p className="py-0 mb-2">{product[0]?.location_name[0] || "--"}</p>
                  </Col>
                  <Col>
                    {

                      // <><p className="py-1 mb-0"> <b> Owner </b></p><p className="py-0 mb-2">{product[0]?.owner_info[0]?.name}</p></>
                    }
                  </Col>
                </Row>
              </div>
            </div>
            <Tabs
              // defaultActiveKey="profile"
              activeKey={activeTab} // Set active tab
              onSelect={(k) => setActiveTab(k)} // Handle tab change
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="profile" title="Tracking Details">

                {
                  product[0]?.description  ? (

                    <p style={{ textTransform: 'capitalize' }} className="mb-2">{product[0]?.description}</p>
                  ) : (

                    <p style={{ textTransform: 'capitalize' }} className="mb-2 text-warning">{'Owner did not added tracking info yet'}</p>
                  )
                }
              </Tab>
              <Tab eventKey="details" title=" Details">
                {
                  product[0]?.documents ? (
                    <Col xs={12} md={6} lg={6}>
                      <p className="py-1 mb-1"> <b style={{ fontWeight: '600' }}> Documents </b></p>
                      {product[0]?.documents?.map((doc, index) => (
                        <Button size="sm" className="btn btn-sm mb-2 px-2" variant="outline-success">
                          <a
                            href={doc}
                            target="_blank"
                            rel="noreferrer"
                            className="text-white"
                          >
                            Doc {index + 1}
                          </a>
                        </Button>
                      ))}
                    </Col>
                  ) : (
                    <Col>
                      <p className="py-1"> <b style={{ fontWeight: '600' }}>Product Document </b></p>
                      <h5 style={{ textTransform: 'capitalize', fontWeight: '400' }} className="currentPrice py-1 mb-2">-</h5>
                    </Col>
                  )
                }
              </Tab>
              {currentOwnerId === myUserId && <Tab eventKey="certification" title="Certifications">
                {
                  product[0]?.certificate.length > 0 ? (
                    <Col xs={12} md={6} lg={6}>

                      {product[0]?.certificate?.map((doc, index) => (
                        <Button size="sm" variant="outline-success">
                          <a
                            href={doc}
                            target="_blank"
                            rel="noreferrer"
                            className="text-white"
                          >
                            Certificate {index + 1}
                          </a>
                        </Button>
                      ))}
                    </Col>
                  ) : (


                    <Formik
                    initialValues={{
                      title: "", // Added title
                      description: "", // Added description
                      model: "",
                      Manufacturer: "",
                      Company: "",
                      store_link: "",
                      files: [], // Updated to handle multiple files
                    }}
                    onSubmit={(values, { resetForm }) => {
                      console.log("🚀 ~ Form Submitted:", values);
                      AddCertificationsData(values); // Function to handle form submission
                      resetForm(); // Reset form after submission
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      } = props;
                  
                      const handleFileUpload = (event) => {
                        const files = Array.from(event.target.files);
                        const validFiles = files.filter((file) =>
                          [
                            "application/pdf",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          ].includes(file.type)
                        );
                  
                        if (values.files.length + validFiles.length > 20) {
                          toast.error("You can upload a maximum of 20 documents.");
                          return;
                        }
                  
                        setFieldValue("files", [...values.files, ...validFiles]);
                      };
                  
                      const removeFile = (index) => {
                        const updatedFiles = values.files.filter((_, i) => i !== index);
                        setFieldValue("files", updatedFiles);
                      };
                  
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            {/* Title Field */}
                            <div className="col-12">
                              <InputDiv>
                                <label
                                  htmlFor="title"
                                  className="form-label text-white"
                                  style={{ fontSize: "small" }}
                                >
                                  Title
                                </label>
                                <ChatSearchInput
                                  type="text"
                                  value={values.title}
                                  name="title"
                                  id="title"
                                  placeholder="Enter Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control mt-2 ${errors.title && touched.title ? "error" : ""
                                    }`}
                                />
                             
                              </InputDiv>
                            </div>
                  
                            {/* Description Field */}
                            <div className="col-12">
                              <InputDiv>
                                <label
                                  htmlFor="description"
                                  className="form-label text-white"
                                  style={{ fontSize: "small" }}
                                >
                                  Description
                                </label>
                                <ChatSearchInput
                                  value={values.description}
                                  name="description"
                                  id="description"
                                  placeholder="Enter Description"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={`form-control mt-2 ${errors.description && touched.description ? "error" : ""
                                    }`}
                                />
                               
                              </InputDiv>
                            </div>
                  
                            {/* Display Uploaded Files */}
                            {values?.files.length > 0 && (
                              <div className="mt-3">
                                <div className="row">
                                  {values.files.map((file, index) => (
                                    <div
                                      key={index}
                                      className="col-lg-4 col-md-6 col-12 mb-3 d-flex align-items-center"
                                    >
                                      <div
                                        className="p-3 text-white rounded d-flex flex-column align-items-start"
                                        style={{ minHeight: "120px" }}
                                      >
                                        <span className="mb-2">{file.name}</span>
                                        <Button
                                          variant="outline-danger"
                                          size="sm"
                                          onClick={() => removeFile(index)}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                  
                            {/* File Upload Section */}
                            <div className="col-12 col-md-3">
                              <InputDiv>
                                <label
                                  htmlFor="files"
                                  className="form-label text-white"
                                  style={{ fontSize: "small" }}
                                >
                                  Upload Documents
                                </label>
                                <input
                                  type="file"
                                  id="files"
                                  accept=".pdf,.doc,.docx,.xlsx"
                                  multiple
                                  style={{ display: "none" }}
                                  onChange={handleFileUpload}
                                />
                                <Button
                                  type="button"
                                  size="md"
                                  className="py-2 btn-block"
                                  variant="outline-info"
                                  onClick={() => document.getElementById("files").click()}
                                >
                                  Choose Documents
                                </Button>
                              </InputDiv>
                            </div>
                          </div>


                          <LocationSearch onCoordinatesChange={handleCoordinatesChange} />

                  
                          {/* Submit Button */}
                          <div className="row py-4">
                            <div className="col-12 text-center">
                              <Button
                                type="submit"
                                size="md"
                                className="py-2 btn-block"
                                variant="outline-primary"
                              >
                                Add Data
                              </Button>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                  

                  )
                }
              </Tab>}
              {currentOwnerId === myUserId && <Tab eventKey="transferownership" title="Transfer Ownership">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <CustomSelect
                    // isLoading={searchLoad}
                    name="receiver"
                    onInputChange={handleInputChange}
                    onChange={(value) => {
                      setSelectedUser(value)
                    }}
                    placeholder={
                      selectedUser.label || "Select User"
                    }

                    // value={receiver1}
                    options={usersList}
                    isSearchable
                  />{" "}

                </div>
                <Button size="sm" disabled={!selectedUser} onClick={transferOwnerFnc} className="btn btn-sm mb-2 mt-2 px-2" variant="outline-primary">
                  Transfer Ownership
                </Button>



              </Tab>}

            </Tabs>
            <hr className="mt-2 mx-0" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
          </div>
        </div> : <h5>No Data Found!</h5>}

      </Container>
      <div className='py-4'>
        <OwnerStepper owners={currentOwners} />
      </div>


    </>
  );
};

export default ProductTrackingDetails;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;