import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const OwnerStepper = ({ owners }) => {
  return (
    <Container>
        <h4>Ownership Details</h4>
      <ResponsiveRow>
        {owners?.map((owner, index) => (
          <StepWrapper key={owner._id}>
            <StepCircle isActive={owner.status === "active"}>
              <img
                src={owner.owner_info[0]?.image}
                alt={owner.owner_info[0]?.name}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </StepCircle>
            <OwnerName isActive={owner.status === "active"}>
              {owner.owner_info[0]?.name || "Unknown"}
            </OwnerName>
            {index < owners.length - 1 && <StepLine />}
          </StepWrapper>
        ))}
      </ResponsiveRow>
    </Container>
  );
};

export default OwnerStepper;

const ResponsiveRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 60px; /* Adjust spacing for mobile */
  }
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: 40px;
    height: 2px;
    background-color: #00897b;

    @media (max-width: 768px) {
      width: 2px;
      height: 40px;
      left: 50%;
      top: auto;
      bottom: -40px;
      transform: translateX(-50%);
    }
  }
`;

const StepCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  border: 2px solid #00897b;
  filter: ${(props) => (props.isActive ? "none" : "grayscale(100%)")};
`;

const StepLine = styled.div`
  display: none; /* Use pseudo-elements for lines */
`;

const OwnerName = styled.p`
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
  text-transform: capitalize;
  color: ${(props) => (props.isActive ? "#ffffff" : "#343a40")};
`;
