import React from 'react';
import { Badge } from 'react-bootstrap';

const StatusComponent = ({ title, status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'active':
        return 'success';
      case 'inactive':
        return 'danger';
      default:
        return 'primary';
    }
  };

  return (
    <div className="col-lg-3 col-md-12 py-2 col-sm-6">
      <p className="py-1">
        <b style={{ fontWeight: "700" }}>{title}</b>
      </p>
      <p
        style={{
          textTransform: "capitalize",
          fontWeight: "400",
        }}
        className="currentPrice py-2 mb-2"
      >
        <Badge bg={getStatusColor(status)}>
          {status}
        </Badge>
      </p>
    </div>
  );
};

export default StatusComponent;
