/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Ring } from '@uiball/loaders';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getMyStore,
    add,
} from '../../../utils/services/user.service';
import { Button } from '../../../shared/components/Button';
import '../style/storedetail.css';
import SocialMediaLink from '../../../shared/components/OtcMarketplace/SocialMediaLink';
import StatusComponent from '../../../shared/components/StatusCompoent/StatusComponent';
import InfoItem from '../../../shared/components/OtcMarketplace/InfoItem';
import ProjectMedia from './ProjectsMedia';
import UploadFileModal from './AddProjectMediaModal';

const ProjectDetailPage = ({ data, projectId }) => {
    const [storeId, setstoreId] = useState([]); // Get the store ID from URL parameter
    const [storeData, setStoreData] = useState(null);
    const [A, AA] = useState([]);
    const [B, BB] = useState([]);
    const [A1, AAA] = useState([]);
    const [B1, BBB] = useState([]);
    const [A2, AAAA] = useState([]);
    const [B2, BBBB] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setloading] = useState(false);
    const [storeSellingStaus, setStoreSellingStatus] = useState(true);
    const [refreshProducts, setRefreshProducts] = useState(false);
    const [showFullAddress, setShowFullAddress] = useState(false);
    const [getStoreName, setStoreName] = useState('');
    const [showFullAddress1, setShowFullAddress1] = useState(false);
    const [showFullAddress2, setShowFullAddress2] = useState(false);
    const [storeData2, setStoreData2] = useState(null);
    const [storeData2Id, setStoreData2Id] = useState(null);
    const [showAddMediaModal, setShowAddMediaModal] = useState(false);
    const [reFetch, setRefetch] = useState(false);

    useEffect(() => {
        const fetchStoreData = async () => {
            try {
                setloading(true);
                const response = await getMyStore(); // Replace with your API call
                if (response.data && response.data.result?.length > 0) {
                    const storeInfo = response.data.result[0];
                    console.log(storeInfo?.selling_status, '----') // Extract the first store
                    setStoreData2(storeInfo);
                    // eslint-disable-next-line no-underscore-dangle
                    setStoreData2Id(storeInfo._id);
                    // console.log('storeInfo >>>>>>>>>>>>>>>>>>>', storeInfo);
                    // eslint-disable-next-line no-underscore-dangle
                    // console.log('storeInfo ID >>>>>>>>>>>>>>>>>>>', storeInfo._id);
                    // eslint-disable-next-line no-underscore-dangle
                    setstoreId(storeInfo._id);
                    setStoreSellingStatus(storeInfo?.selling_status)
                    setloading(false);
                } else {
                    setloading(false);
                    console.error('Error fetching store data:', response.statusText);
                }
            } catch (error) {
                setloading(false);
                console.error('Error fetching store data:', error);
            }
        };
        // console.log('storeData2Id 222222', storeData2Id);

        const fetchStoreById = async () => {
            try {
                setloading(true);
                // eslint-disable-next-line no-underscore-dangle
                const response = await getMyStore(storeData2._id); // Replace with your API call
                if (response.data) {
                    setloading(false);
                    setStoreData(response.data); // Set the detailed store data
                    // console.log('store Data store Data store Data store Data store Data', response.data);
                    AA(response.data.result[0]?.storeAddress);
                    BB(showFullAddress ? response.data.result[0]?.storeAddress : response.data.result[0]?.storeAddress.slice(0, 10));
                    AAA(response.data.result[0]?.approver_reason);
                    BBB(showFullAddress1 ? response.data.result[0]?.approver_reason : response.data.result[0]?.approver_reason.slice(0, 10));
                    AAAA(response.data.result[0]?.rejection_reason);
                    BBBB(showFullAddress2 ? response.data.result[0]?.rejection_reason : response.data.result[0]?.rejection_reason.slice(0, 10));
                    const address = storeData.result[0]?.rejection_reason;
                    const truncatedAddress = showFullAddress ? address : address.slice(0, 10);
                    setStoreName(storeData.result[0]?.storeName);
                } else {
                    setloading(false);
                    console.error('Error fetching store details:', response.statusText);
                }
            } catch (error) {
                setloading(false);
                console.error('Error fetching store details:', error);
            }
        };

        fetchStoreData();
        // console.log('storeData2 check', storeData2);
        // console.log('storeData2Id 11111', storeData2Id);
        if (storeData2 !== null) {
            // eslint-disable-next-line no-underscore-dangle
            // console.log('storeData2 Id check', storeData2._id);
            fetchStoreById();
        }
    }, [storeId, storeSellingStaus, refreshProducts]);



    const handleRefreshProducts = () => {
        // Toggle the state to trigger re-rendering of the Product component
        setRefreshProducts(prevState => !prevState);
    };


    const handleSubmitMedia = async (value) => {
        setIsLoading(true)
        console.log("🚀 ~ handleSubmitMedia ~ value:", value);
        const formData = new FormData();
        formData.append("title", value.title);
        formData.append("description", value.description);
        formData.append("project_id", projectId ); // Add project_id if available, fallback to empty string
        // Append images
        value.images.forEach((image, index) => {
            if (image instanceof File) {
                formData.append(`File`, image); // Use array-like notation to allow multiple files
            }
        });
        // Append documents
        value.documents.forEach((document, index) => {
            if (document instanceof File) {
                formData.append(`File2`, document); // Use array-like notation for multiple documents
            }
        });
        
        try {
            const response = await add(formData);
            toast.success(response?.data?.Message)
            setShowAddMediaModal(false)
            setIsLoading(false)
            setRefetch(!reFetch)
            
        } catch (error) {
            setIsLoading(false)
            toast.error(error?.response?.data?.Message)
        }
    };
    

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? 'flex' : 'none', // Use flex display when loading
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 999999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div className="container py-3">

                {storeData ? (
                    <div className="row px-4 mt-2">
                        <div
                            className="col-lg-4 order-1 order-lg-1 hero-img py-1 justify-content-center"
                            data-aos="fade-right"
                            data-aos-delay="200"
                        >
                            <div className="square-img-wrapper">
                                <img src={data?.image} className="card-img-top square-img" alt="storelogo" />
                            </div>

                        </div>


                        <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-3">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <h3 style={{ textTransform: 'Capitalize' }} id="h2" data-aos="fade-up">{data?.name}</h3>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    {/* {storeData.result[0].status === 'approved' && ( */}

                                    <Button onClick={() => setShowAddMediaModal(true)} variant={'outline-primary'} className={'text-white'}>
                                        Add
                                    </Button>



                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <Tabs
                                        defaultActiveKey="home"
                                        transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3 mt-4"
                                    >
                                        <Tab eventKey="home" ClassName="text-white" title="Project Info">

                                            <div className="row text-center">
                                                <InfoItem
                                                    label="Project ID"
                                                    value={data?._id}
                                                />
                                                <InfoItem
                                                    label="Owner Name"
                                                    value={data?.owner_details[0]?.name}
                                                />
                                                <InfoItem
                                                    label="Email"
                                                    value={data?.email}
                                                />
                                                <InfoItem
                                                    label="Phone Number"
                                                    value={data?.phone_number}
                                                />

                                                <SocialMediaLink platform="Explorer Link" url={data?.explorer_link} />

                                                <InfoItem
                                                    label="Description"
                                                    value={data?.description}
                                                />
                                                <StatusComponent
                                                    title=" Status"
                                                    status={data?.status}
                                                />
                                                {data?.insta_link &&
                                                    <SocialMediaLink platform="Instagram" url={data?.insta_link} />
                                                }
                                                {data?.telegram_link &&
                                                    <SocialMediaLink platform="Telegram" url={data?.telegram_link} />
                                                }
                                                {data?.x_link &&
                                                    <SocialMediaLink platform="X" url={data?.x_link} />
                                                }
                                                {data?.document?.length > 0 &&
                                                    <SocialMediaLink platform="Document" url={data?.document[0]} />
                                                }




                                            </div>

                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>

                        </div>
                    </div>
                ) : (
                    <div className="row text-center">
                        <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
                        <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center">
                            <p className="py-4">loading...</p>

                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
                    </div>
                )}
                <hr className="mt-5 mx-4" style={{ border: '1px solid #4d4d4d', opacity: '1.1' }} />
                <div>
                    <ProjectMedia reFetch={reFetch} projectId={projectId} onDeleteProduct={handleRefreshProducts} />
                </div>

                {/* Your Store Detail Card */}
            </div>


            <UploadFileModal onSubmit={handleSubmitMedia} show={showAddMediaModal} hide={() => setShowAddMediaModal(false)} />
        </>



    );
};

export default ProjectDetailPage;
