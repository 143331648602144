import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "@/shared/components/Button";
import { FaFileAlt } from "react-icons/fa";

const UploadFileModal = ({ show, hide, onSubmit }) => {
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        setImages([...images, ...files]);
    };

    const handleDocumentUpload = (e) => {
        const files = Array.from(e.target.files);
        setDocuments([...documents, ...files]);
    };

    const handleRemoveFile = (type, index) => {
        if (type === "image") {
            setImages(images.filter((_, i) => i !== index));
        } else if (type === "document") {
            setDocuments(documents.filter((_, i) => i !== index));
        }
    };

    return (

        
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={hide}
        >


            <Modal.Header closeButton>
                <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                    <b>Upload Files</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container padding-bottom-3x p-0">
                    <div className="flex flex-col gap-4" style={{ fontFamily: "sans-serif" }}>
                        {/* Title Input */}
                        <InputDiv>
                            <label htmlFor="title" className="form-label text-white">
                                Title
                            </label>
                            <ChatSearchInput
                                type="text"
                                name="title"
                                value={formData.title}
                                placeholder="Enter title"
                                onChange={handleInputChange}
                            />
                        </InputDiv>

                        {/* Description Input */}
                        <InputDiv>
                            <label htmlFor="description" className="form-label text-white">
                                Description
                            </label>
                            <ChatSearchTextarea
                                name="description"
                                value={formData.description}
                                placeholder="Enter description"
                                onChange={handleInputChange}
                            />
                        </InputDiv>


                        {/* File Upload: Images */}
                        <InputDiv>
                            <label htmlFor="images" className="form-label text-black">
                                Upload Images
                            </label>
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleImageUpload}
                                className="form-control mt-2"
                            />
                            <PreviewContainer>
                                {images.map((file, index) => (
                                    <PreviewItem key={index}>
                                        <img src={URL.createObjectURL(file)} alt="preview" />
                                        <button onClick={() => handleRemoveFile("image", index)}>Remove</button>
                                    </PreviewItem>
                                ))}
                            </PreviewContainer>
                        </InputDiv>

                        {/* File Upload: Documents */}
                        <InputDiv>
                            <label htmlFor="documents" className="form-label text-black">
                                Upload Documents
                            </label>
                            <input
                                type="file"
                                multiple
                                accept=".pdf,.doc,.docx"
                                onChange={handleDocumentUpload}
                                className="form-control mt-2"
                            />
                            <PreviewContainer>
                                {documents.map((file, index) => (
                                    <PreviewItem key={index}>
                                        <div className="document-preview">
                                            <FaFileAlt />

                                            <p>{file.name}</p>
                                        </div>
                                        <button onClick={() => handleRemoveFile("document", index)}>Remove</button>
                                    </PreviewItem>
                                ))}
                            </PreviewContainer>

                        </InputDiv>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <FormButton variant="outline-success" className={'text-black'} onClick={() => onSubmit({ ...formData, images, documents })}>
                    Submit
                </FormButton>
                <FormButton variant="contain-success" onClick={hide}>
                    Cancel
                </FormButton>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadFileModal;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    border-bottom: 2px solid #47c9a4;
  }
`;

const FormButton = styled(Button)`
  color: white;
  margin-top: 1rem;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const PreviewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
  }

  p {
    font-size: 12px;
    text-align: center;
  }

  button {
    font-size: 10px;
    color: red;
    border: none;
    background: none;
    cursor: pointer;
  }
`;
const ChatSearchTextarea = styled.textarea`
    width: 100%;
    height: 100%;
    font-size: 12px;
    transition: all 0.3s;
    background: transparent;
    border: none;
    padding-left: 2px;
    border-bottom: 1px solid #b1b1b1;
    color: #b1b1b1;

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        color: #b1b1b1;
        background: transparent;
        border-bottom: 2px solid #47c9a4;
    }

    &::-webkit-input-placeholder {
        color: #b1b1b1;
    }
    &::-moz-placeholder {
        color: #b1b1b1;
    }
    &:-moz-placeholder {
        color: #b1b1b1;
    }
    &:-ms-input-placeholder {
        color: #b1b1b1;
    }
`;

