import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap is imported

import Panel from "@/shared/components/Panel";
import { Ring } from "@uiball/loaders";
import { getProject } from "../../../utils/services/user.service";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import { Button } from "../../../shared/components/Button";
import { getStatusBadge } from "../../../utils/constants/contants";

const AllProjects = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [myProjectdata, setMyProjectData] = useState([])
    const user = JSON.parse(localStorage.getItem("user"));

    const fetchMyProject = async () => {
        setIsLoading(true);
        try {
            const res = await getProject();

            // Filter the projects where user_id does not match the specified user id
            const filteredProjects = res?.data?.data.filter(
                (project) => project.user_id !== user?.data.user.user_id
            );
            setMyProjectData(filteredProjects);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("🚀 ~ fetchMyProject ~ error:", error);
        }
    };


    useEffect(() => {
        fetchMyProject()
    }, [])
    const tableConfig = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        isPagination: true,
    };

    const header = [
        { accessor: "_id", Header: "Id" },
        { accessor: "user_id", Header: "User Id" },
        { accessor: "name", Header: "Name" },
        { accessor: "status", Header: "Status" },

    ];

    const data = [];
    myProjectdata.map((item) =>
        data.push({
            user_id: item.user_id,
            _id: item._id,
            name:

                <span className="d-flex text-nowrap">
                    {item?.name}
                </span>
            ,

            status: (

                <span className="d-flex text-nowrap">
                    {getStatusBadge(item?.status)}
                </span>
            ),
        })
    );

    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Panel lg={12} title="All Projects" closeBtn minusBtn refreshLoad>

                <ReactTableBase
                    columns={header}
                    data={data}
                    tableConfig={tableConfig}
                />


            </Panel>
        </>
    );
};

export default AllProjects;
