/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../style/Rating.css';
import {
  Col, Row,
} from 'react-bootstrap';
import { deleteProjectImagesData, getProjectImagesData } from '../../../utils/services/user.service';
import { toast } from 'react-toastify';
import { Ring } from '@uiball/loaders';

const ProjectMedia = ({ projectId,reFetch, onDeleteProduct }) => {
  const [projectMedia, serProjectMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const NoImg1 = `${process.env.PUBLIC_URL}/img/No-img1.png`;
  const fetchProjectMediaData = async () => {
    const payload = {
      limit: 100000,
      page_number: 1,
      project_id: projectId,
    };
    try {
      const response = await getProjectImagesData(payload);

      serProjectMedia(response.data.data);
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  useEffect(() => {
    fetchProjectMediaData();
  }, [projectId, reFetch]);
  const handleDelete = async (id) => {
    setIsLoading(true)
    try {
      const payload = {
        record_id: id,
        project_id: projectId
      };
      const res = await deleteProjectImagesData(payload);
      toast.success(res?.data?.Message);
      setIsLoading(false)
      fetchProjectMediaData()
    } catch (error) {
      console.error('Error deleting product:', error);
      setIsLoading(false)
      toast.error(error?.response?.data.Message || 'something went wrong!');

    }
  };

  return (
    <div className="container mt-4">
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="row px-4">
        {projectMedia.length > 0 ? (
          projectMedia.map(product => (
            <div
              className="col-lg-3 col-md-6 col-sm-12"
              style={{ textTransform: 'capitalize' }}
              key={product._id}
            >
              <div className="card productCard" style={{ background: '#232329' }}>
                <Link to={`/mediaDetail/${product._id}`}>

                  <div className="square-img-wrapper mt-4">
                    <img
                      src={product.image.length > 0 ? product.image[0] : NoImg1}
                      className="card-img-top square-img p-3 border-radius zoom-in-on-hover"
                      alt={product.storeName}
                    />
                  </div>
                </Link>
                <div className="card-body">
                  <Row>

                    <Col xs={12} md={7} lg={7}>
                      <h5 className="card-title producttitle mt-0 mb-4">{product.title}</h5>
                    </Col>
                  </Row>

                  <div className="overlay-text-delete " style={{
                    right: '10px !important'
                  }} onClick={() => handleDelete(product?._id)}>
                    <i className="fa fa-trash-o text-white px-1" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </div>
          ))
        ) : (
          <p className="text-center mb-5">No Products available.</p>
        )}
      </div>
    </div>
  );
};
export default ProjectMedia;

// region STYLES

