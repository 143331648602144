import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { getVoteOptionCount } from '../../../../utils/services/user.service';

// Chart options
const options = {
  legend: {
    position: 'bottom',
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: 'rgb(204, 204, 204)',
          borderDash: [3, 3],
        },
        ticks: {
          fontColor: 'rgb(204, 204, 204)',
        },
      },
    ],
  },
};

const RandomAnimatedBars = ({ surveyId, allSurvey }) => {
  const { t } = useTranslation('common');
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: t('All Voting Data'),
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        borderWidth: 1,
        hoverBackgroundColor: '#FF6384',
        hoverBorderColor: '#FF6384',
        data: [],
      },
    ],
  });

  // Fetch data from the API
  const fetchCount = async () => {
    try {
      const payload = { survey_id: surveyId };
      const res = await getVoteOptionCount(payload);

      if (res?.data?.count) {
        // Transform API response into chart data format
        const labels = res.data.count.map((item) => `Option ${item._id}`);
        const data = res.data.count.map((item) => item.total);

        setChartData({
          labels,
          datasets: [
            {
              label: t('All Voting Data'),
              backgroundColor: '#FF6384',
              borderColor: '#FF6384',
              borderWidth: 1,
              hoverBackgroundColor: '#FF6384',
              hoverBorderColor: '#FF6384',
              data,
            },
          ],
        });
      }
    } catch (error) {
      console.error('Error fetching voting data:', error);
    }
  };

  useEffect(() => {
    fetchCount();
  }, [surveyId, allSurvey]);

  return (

    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>{t('Voting Statistics')}</CardTitle>
        </CardTitleWrap>
        <div>
          <Bar data={chartData} options={options} />
        </div>
      </CardBody>
    </Card>

  );
};

export default RandomAnimatedBars;
